<template>
  <v-container id="subscribe" tag="section">
    <base-card color="rgba(203, 170, 92, 0.51)" class="pa-3">
      <base-btn
        v-for="(item, i) in items"
        :key="i"
        :href="item.href"
        class="ml-0 mr-3"
        color="primary"
        square
        target="_blank"
      >
        <v-icon v-text="item.icon" />
      </base-btn>
    </base-card>
  </v-container>
</template>

<script>
export default {
  name: "HomeSubscribe",
};
</script>
